import { Component } from '@angular/core';
import { ButtonComponent } from '../../../Components/button/button.component';
import { SharedModule } from '../../../shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputComponent } from '../../../Components/input/input.component';
import {
  AgentType,
  AggregatorType,
  DataItem,
  ImageGroup,
  ProjectType,
  SchemeActivityType,
  SchemeType,
  TableCols,
} from '../../../types';
import {
  ACTIVITIES_COLS,
  ACTIVITY_COLS,
  BILL_MATERIAL_COLS,
  CATALOGUE_COLS,
  DOCUMENT_VERSION,
  FORMS_COLS,
  QUOTE_COLS,
  SCHEDULE_TYPE,
} from '../../../constants';
import { ManageFieldTypeComponent } from './manage-field-type/manage-field-type.component';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { ProjectService } from '../project.services';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CatalogueService } from '../../catalogue-management/catalogue.service';
import { trimFormData } from '../../common-methods';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import JSZip from 'jszip';
import { environment } from '../../../../environments/environment';
import { TaskService } from '../../task-management/task-management.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-job-details',
  standalone: true,
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.scss',
  providers: [MessageService],
  imports: [
    ButtonComponent,
    SharedModule,
    InputComponent,
    ManageFieldTypeComponent,
    NumberInputComponent,
    CalendarModule,
  ],
})
export class JobDetailsComponent {
  jobData: any;
  ScheduleDialog: boolean = false;
  ScheduleForm!: FormGroup;
  AgentList: AgentType[] = [];
  activityCols: TableCols[] = ACTIVITY_COLS;
  ActivitiesList: any[] = [];
  ActivitiesData: any[] = [];
  ActivitiesCols: TableCols[] = ACTIVITIES_COLS;
  // selectedRows: any[] = [];
  addActivities: boolean = false;
  AggregatorList: AggregatorType[] = [];
  AssessmentList: any[] = [];
  formFields: any[] = [];
  InstallationList: any[] = [];
  billMaterialCols: TableCols[] = BILL_MATERIAL_COLS;
  billMaterialList: any[] = [];
  billMaterialDialog: boolean = false;
  selectedBillRows: any = null;
  CatalogueList: any[] = [];
  catalogueCols: TableCols[] = CATALOGUE_COLS;
  quoteForm!: FormGroup;
  editQuote: boolean = false;
  QuoteCols: TableCols[] = QUOTE_COLS;
  QuoteList: any[] = [];
  onHover: number = 0;
  clonedActivityList: { [s: string]: any } = {};
  isLoading: boolean = true;
  isActivityLoading: boolean = false;
  skeletonRows = new Array(10);
  skeletonRowsActivities = new Array(2);
  skeletonRowsBOM = new Array(5);
  allActivitiesClone: any[] = [];
  deleteQuoteDialog: boolean = false;
  rowDataQuote: any;
  rowDataActivity: any;
  schedule_type: ProjectType[] = SCHEDULE_TYPE;
  onSaveActivityLoad: boolean = false;
  isLoadingFields: boolean = true;
  isLoadingActivity: boolean = true;
  onLoadingBOM: boolean = false;
  isLoadingBOM: boolean = true;
  isLoadingPrice: boolean = true;
  projectData: any;
  metaData: any;
  scheduleInstallationButton: boolean = false;
  taskList: any[] = [];
  task: any;
  deviceTypes: any[] = [];
  FormsCols: TableCols[] = FORMS_COLS;
  documentVersionCol: TableCols[] = DOCUMENT_VERSION;
  deleteActivityDialog: boolean = false;
  certificateValue: any[] = [];
  minDate: Date = new Date();
  onSaveSchedule: boolean = false;

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: ProjectService,
    private messageService: MessageService,
    private catalogueService: CatalogueService,
    private http: HttpClient,
    private taskService: TaskService,
    private sanitizer: DomSanitizer
  ) {
    this.initializeScheduleForm();
    this.initializeQuoteForm();
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'ACTIVE':
        return 'tag-active';
      case 'ASSESSMENTCOMPLETE':
        return 'tag-assessment-complete';
      case 'AUDIT':
        return 'tag-audit';
      case 'Audit_Aggregator':
        return 'tag-audit-aggregator';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'ASSESSMENT':
        return 'tag-job-assessment';
      default:
        return '';
    }
  }

  initializeScheduleForm(): void {
    this.ScheduleForm = this.formBuilder.group({
      type: [this.schedule_type[0]],
      agent: ['', Validators.required],
      date: ['', Validators.required],
      fromTime: [new Date(), Validators.required],
      toTime: [new Date(), Validators.required],
    });
  }

  initializeQuoteForm(): void {
    this.quoteForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      price: ['', Validators.required],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async ngOnInit(): Promise<void> {
    this.jobData = history.state.jobData;
    this.projectData = history.state.projectData;
    await this.getJobData();
    await this.getBOM();
    this.QuoteList = await this.getJobQuote();
    this.ActivitiesList = await this.getJobActivities();
    await this.getJobFields();
    await this.getCertificateCount();
  }

  async getTaskList(): Promise<any[]> {
    try {
      const data = await this.taskService.fetchInstallerTaskData();
      return data;
    } catch (error: any) {
      return [];
    }
  }

  private async getAggregatoreList(id: number): Promise<AggregatorType[]> {
    try {
      const res = await this.service.fetchAggregatorList(id);
      return res;
    } catch (error: any) {
      return [];
    }
  }

  async onSelectAggregatore(rowData: any): Promise<void> {
    this.AggregatorList = await this.getAggregatoreList(rowData.activityId);
  }

  async onChangeAggregatore(rowData: any): Promise<any[]> {
    const { id, jobId, discount, activityId, aggregator } = rowData;
    try {
      const payload = {
        id,
        jobId,
        discount,
        activityId,
        AggregatorId: aggregator.id,
      };
      const res = await this.service.updateAggregator(payload);
      if (res) this.ActivitiesList = await this.getJobActivities();
      return res;
    } catch (error: any) {
      return [];
    }
  }

  async getJobData(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobDataById(this.jobData.id);
      if (res) {
        this.jobData = res;
        this.isLoading = false;
      }
      return res;
    } catch (error: any) {
      return [];
    }
  }

  navigateBack(): void {
    window.history.back();
  }

  closeDialog(): void {
    this.ScheduleDialog = false;
    this.deleteQuoteDialog = false;
    this.deleteActivityDialog = false;
  }

  private convertToNumberArray(inputArray: any) {
    return inputArray.flatMap((item: any) => {
      if (typeof item === 'string') {
        return item.split(',').map((str) => Number(str.trim()));
      } else if (typeof item === 'number') {
        return [item];
      } else {
        return [];
      }
    });
  }

  async onSchedule(type: string): Promise<void> {
    this.onSaveSchedule = true;
    const { fromTime, toTime, date, agent } = this.ScheduleForm.value;
    try {
      const payload = {
        AgentIds: this.convertToNumberArray(agent),
        ProjectId: this.jobData.projectId,
        JobId: this.jobData.id,
        JobType: type,
        ScheduleDate: moment(date).format('YYYY-MM-DD'),
        FromTime: moment(fromTime).format('HH:mm:ss'),
        EndTime: moment(toTime).format('HH:mm:ss'),
      };
      const res = await this.service.scheduleJob(trimFormData(payload));
      if (res) {
        this.ScheduleDialog = false;
        this.onSaveSchedule = false;
        this.successToast('Job Scheduled Successfully');
      }
    } catch (error: any) {
      this.onSaveSchedule = false;
      this.errorToast(error.message);
    }
  }

  private async getFilteredAgent(data: any[]): Promise<any[]> {
    try {
      this.deviceTypes = data.map((c: any) => c.activity.deviceType);
      const res = await this.service.fetchJobAgentList(this.deviceTypes);
      const updatedData = res.map((c: any) => {
        const license = Array.from(new Set(c.licenseTypes.split(', '))).join(
          ', '
        );
        return {
          ...c,
          name: `${c.name} - ${license}`,
        };
      });
      return updatedData;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  private async getAgentList(): Promise<any[]> {
    try {
      const res = await this.service.fetchAgentList();
      const updatedData = res.map((c: any) => {
        const license = Array.from(new Set(c.licenseTypes.split(', '))).join(
          ', '
        );
        return {
          ...c,
          name: `${c.name} - ${license}`,
        };
      });
      return updatedData;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async toSchedule(type: string): Promise<void> {
    if (this.scheduleInstallationButton) {
      this.errorToast(
        'All the Activities should be associated with an Aggregator.'
      );
    } else {
      if (type === 'ASSESSMENT') {
        this.AgentList = await this.getAgentList();
      }
      this.ScheduleDialog = true;
      this.initializeScheduleForm();
    }
  }

  onTypeChange(type: ProjectType): void {
    this.ScheduleForm.patchValue({ type });
  }

  async handleAddNewActivities(): Promise<void> {
    if (this.ActivitiesList.length > 0) {
      this.errorToast('There are activities linked to this job already.');
    } else {
      this.addActivities = true;
      // this.selectedRows = [];
      this.ActivitiesData = [];
      this.task = null;
      this.taskList = await this.getTaskList();
    }
  }

  async createTaskActivities(): Promise<any[]> {
    this.isActivityLoading = true;
    this.onSaveActivityLoad = true;
    // const taskIds = this.task.map((num: number) => num.toString());
    const taskIds = [this.task.toString()];
    try {
      const res = await this.service.getTaskActivitiesData(
        this.jobData.id,
        taskIds
      );
      if (res)
        if (res) {
          this.isActivityLoading = false;
          this.ActivitiesList = await this.getJobActivities();
          this.addActivities = false;
          this.onSaveActivityLoad = false;
          this.QuoteList = await this.getJobQuote();
          this.successToast('Job Activities Added Successfully');
        }
      const data = res.flatMap((scheme: SchemeType) =>
        scheme.schemeActivities.map((activity: SchemeActivityType) => ({
          ...activity,
          schemeName: scheme.name,
        }))
      );
      this.allActivitiesClone = data;
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async getJobActivities(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobActivitiesData(this.jobData.id);
      if (res) {
        this.isLoadingActivity = false;
        this.AgentList = await this.getFilteredAgent(res);
        const allHaveAggregatorName = res.every((item: any) => item.aggregator);
        this.scheduleInstallationButton = !allHaveAggregatorName;
      }
      return this.filterActivities(res);
    } catch (error: any) {
      this.isLoadingActivity = false;
      this.errorToast(error.message);
      return [];
    }
  }

  // Remove duplicates based on activityId
  private filterActivities(data: any) {
    const uniqueData = data.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.activityId === current.activityId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniqueData;
  }

  private async createQuoteFromTask(): Promise<void> {
    try {
      const res = await this.service.createQuoteFromTasks(
        this.jobData.id,
        this.task
      );
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async handleAddActivities(): Promise<void> {
    await this.createTaskActivities();
  }

  closeActivityDialog(): void {
    this.addActivities = false;
  }

  async onSaveJob(): Promise<void> {
    const fieldData = this.AssessmentList.concat(this.InstallationList);

    const value = fieldData.filter(
      (item) =>
        item.field.fieldType !== 'Photo' || item.field.fieldType === 'Signature'
    );

    const payload = value.reduce((acc, item) => {
      if (
        item.value !== undefined &&
        item.value !== 'null' &&
        item.value !== '' &&
        item.value !== null
      ) {
        const fieldPayload: { JobFieldId: any; value: any; id?: any } = {
          JobFieldId: item.id,
          value:
            typeof item.value !== 'string' ? String(item.value) : item.value,
        };

        const lastJobFieldId = item.jobFieldValues.at(-1)?.id;
        if (lastJobFieldId) {
          fieldPayload.id = lastJobFieldId;
        }

        acc.push(fieldPayload);
      }
      return acc;
    }, []);

    try {
      const res = await this.service.createJobFieldsValue(payload);
      if (res) {
        window.history.back();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  onCancelJob(): void {
    window.history.back();
  }

  private async getCataloguePrice(): Promise<any[]> {
    try {
      const res = await this.catalogueService.fetchPriceData();
      if (res) this.isLoadingPrice = false;
      return res.data;
    } catch (error: any) {
      this.isLoadingPrice = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async onAddBillMaterial(): Promise<void> {
    this.billMaterialDialog = true;
    this.selectedBillRows = null;
    this.CatalogueList = await this.getCataloguePrice();
  }

  onCancelBillMaterial(): void {
    this.billMaterialDialog = false;
  }

  async onSaveBillMaterial(): Promise<void> {
    this.onLoadingBOM = true;
    try {
      const payload = this.selectedBillRows.map((c: any) => {
        return {
          projectId: this.jobData.projectId,
          jobId: this.jobData.id,
          catalougeId: c.id,
          model: c.model,
          manufacturer: c.manufacturer,
          deviceType: c.deviceType,
          rrp: c.eligibility.rrp,
          quantity: c.quantity ?? 1,
        };
      });
      const res = await this.service.createJobBOM(payload);
      if (res) {
        await this.getBOM();
        this.billMaterialDialog = false;
        this.onLoadingBOM = false;
        this.successToast('Bill of Material Added Successfully');
      }
    } catch (error: any) {
      this.onLoadingBOM = false;
      this.errorToast(error.message);
    }
  }

  private async getBOM(): Promise<any[]> {
    try {
      const payload = {
        projectId: this.jobData.projectId,
        jobId: this.jobData.id,
      };
      const res = await this.service.fetchJobBOM(payload);
      if (res) {
        this.isLoadingBOM = false;
        this.billMaterialList = res;
      }
      return res;
    } catch (error: any) {
      this.isLoadingBOM = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private async getJobQuote(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobQuote(this.jobData.id);
      return res;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async getJobFields(): Promise<DataItem[]> {
    try {
      const res = await this.service.fetchJobFields(this.jobData.id);

      const response = res.filter(
        (item: any) => item.field.fieldType !== 'Constant'
      );

      const photoValuesWithId = this.extractPhotoValues(response);

      if (photoValuesWithId) this.isLoadingFields = false;

      const data = this.getFieldValueDropdown(response);

      const updatedData = this.updateDataWithImages(data, photoValuesWithId);

      this.AssessmentList = updatedData.filter(
        (item: any) => item.stage === 'ASSESSMENT'
      );
      this.InstallationList = updatedData.filter(
        (item: any) => item.stage === 'INSTALLATION'
      );

      this.formFields = updatedData.filter(
        (item: any) => item.field.purpose === 'FORM'
      );

      return updatedData;
    } catch (error: any) {
      this.isLoadingFields = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private getFieldValueDropdown(res: any) {
    const modifiedData = res.map((item: any) => {
      let fieldDropdown = [];

      if (
        item.field &&
        item.field.fieldValues &&
        item.field.fieldValues.length > 0
      ) {
        fieldDropdown = JSON.parse(item.field.fieldValues.at(-1).values);
      }

      return {
        ...item,
        fieldDropdown,
      };
    });

    return modifiedData;
  }

  private extractPhotoValues(res: any[]): { id: number; images: any }[] {
    const data = res
      .filter(
        (item) =>
          item.field.fieldType === 'Photo' ||
          item.field.fieldType === 'Signature'
      )
      .map((item) => {
        return {
          id: item.id,
          images: this.handleImages(item.jobFieldValues.at(-1)?.files),
        };
      });
    return data;
  }

  handleImages(res: any): any[] {
    const fields = res;
    const safeUrls: any[] = [];

    for (const filePath in fields) {
      if (fields.hasOwnProperty(filePath)) {
        const base64Data = fields[filePath];
        const safeUrl = this.convertImage(base64Data);
        if (safeUrl) {
          safeUrls.push({ imageBinary: safeUrl, path: filePath });
        }
      }
    }

    return safeUrls;
  }

  convertImage(base64Data: string): any {
    try {
      if (!base64Data || base64Data.trim() === '') {
        return null;
      }
      // Detect the image type based on the base64 string prefix
      let imageType: string;

      if (base64Data.startsWith('/9j/')) {
        imageType = 'image/jpeg';
      } else if (base64Data.startsWith('iVBORw0KGgo')) {
        imageType = 'image/png';
      } else if (base64Data.startsWith('R0lGODlh')) {
        imageType = 'image/gif';
      } else if (base64Data.startsWith('<svg')) {
        imageType = 'image/svg+xml';
      } else {
        return null;
      }

      const base64Image = `data:${imageType};base64,${base64Data}`;
      return this.sanitizer.bypassSecurityTrustUrl(base64Image); // Safe URL for the image
    } catch (error) {
      return null;
    }
  }

  private updateDataWithImages(res: any[], imagesData: any[]): DataItem[] {
    return res.map((item) => {
      let metaData: any = '';

      if (item.jobFieldValues[0]?.metadata) {
        const decodedString = item.jobFieldValues[0]?.metadata;

        try {
          const jsonArray = JSON.parse(decodedString);
          const parsedData = jsonArray.map((item: any) => JSON.parse(item));

          metaData = parsedData.map((c: any) => {
            // Extract latitude, longitude, and direction references
            const latitude = c['GPS Latitude'] ?? '';
            const latitudeRef = c['GPS Latitude Ref'] ?? '';
            const longitude = c['GPS Longitude'] ?? '';
            const longitudeRef = c['GPS Longitude Ref'] ?? '';

            // Format latitude and longitude with direction indicators
            const formattedLatitude = latitude
              ? `${latitude} ${latitudeRef}`
              : '';
            const formattedLongitude = longitude
              ? `${longitude} ${longitudeRef}`
              : '';

            return {
              GPSLatitude: formattedLatitude,
              GPSLongitude: formattedLongitude,
              DateTime: c['Date/Time'] ?? '',
            };
          });
        } catch (error) {
          console.error('Error parsing metadata:', error);
        }
      }

      const imageGroup = imagesData.find((group) => group.id === item.id);
      if (imageGroup) {
        return { ...item, value: imageGroup?.images, metaData };
      } else {
        return {
          ...item,
          value:
            item.field.fieldType === 'Constant'
              ? item.fieldDropdown
              : item.jobFieldValues.at(-1)?.value || null,
        };
      }
    });
  }

  async quoteValueAdd(): Promise<void> {
    try {
      const payload = {
        name: this.quoteForm.value.name,
        price: this.quoteForm.value.price.toString(),
        jobId: this.jobData.id,
        QuoteType: 'manual',
      };
      const res = await this.service.createJobQuote(payload);
      if (res) {
        this.QuoteList = await this.getJobQuote();
        this.initializeQuoteForm();
        this.successToast('Job Quote Added Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async quoteValueEdit(): Promise<void> {
    try {
      const payload = {
        name: this.quoteForm.value.name,
        price: this.quoteForm.value.price.toString(),
        jobId: this.jobData.id,
        id: this.rowDataQuote.id,
      };
      const res = await this.service.updateJobQuote(payload);
      if (res) {
        this.editQuote = false;
        this.initializeQuoteForm();
        this.QuoteList = await this.getJobQuote();
        this.successToast('Job Quote Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  quoteEdit(rowData: any, i: number): void {
    this.editQuote = true;
    this.rowDataQuote = rowData;
    this.quoteForm.patchValue({
      name: rowData.name,
      price: rowData.price,
    });
  }

  quoteDelete(rowData: any): void {
    this.rowDataQuote = rowData;
    this.deleteQuoteDialog = true;
  }

  jobActivityDelete(rowData: any): void {
    this.rowDataActivity = rowData;
    this.deleteActivityDialog = true;
  }

  async onDeleteJobQuote(): Promise<void> {
    try {
      const res = await this.service.deleteJobQuote(this.rowDataQuote.id);
      if (res) {
        this.deleteQuoteDialog = false;
        this.QuoteList = await this.getJobQuote();
        this.successToast('Job Quote Deleted Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async getCertificateCount(): Promise<void> {
    try {
      const data = await this.service.fetchCertificateCountJob(this.jobData.id);
      if (data) {
        this.certificateValue = data;
      }
    } catch (error: any) {}
  }

  async onDeleteJobActivity(): Promise<void> {
    try {
      const res = await this.service.deleteJobActivity(
        this.rowDataActivity.jobId,
        this.rowDataActivity.id
      );
      if (res) {
        this.deleteActivityDialog = false;
        this.QuoteList = await this.getJobQuote();
        this.ActivitiesList = await this.getJobActivities();
        this.successToast('Job Activity Deleted Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  onRowEditActivity(rowData: any) {
    this.clonedActivityList[rowData.id as string] = { ...rowData };
  }

  onRowActivitySave(rowData: any) {
    if (rowData.price > 0) {
      delete this.clonedActivityList[rowData.id as string];
    } else {
    }
  }

  onRowActivityCancel(rowData: any, index: number) {
    this.ActivitiesList[index] = this.clonedActivityList[rowData.id as string];
    delete this.clonedActivityList[rowData.id as string];
  }

  async onRowBOMSave(rowData: any): Promise<void> {
    try {
      const payload = {
        ...rowData,
        rrp: rowData.rrp * rowData.quantity,
      };
      const data = await this.service.updateJobBOM([payload]);
      if (data) {
        await this.getBOM();
        this.successToast('Quantity Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async onDownloadForm(data: any): Promise<void> {
    await this.fetchForms(data);
  }

  private async fetchForms(data: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    const payload = { ImagePaths: [data] };
    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        this.downloadFile(url, filename);
        return url;
      });

      const images = await Promise.all(imagePromises);
      return images;
    } catch (error) {
      this.isLoading = false;
    }
  }

  private downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
}
