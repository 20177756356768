import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { AGENT_LIST } from '../../../../mock-data';
import { DataItem, ImageGroup, TableCols } from '../../../types';
import { environment } from '../../../../environments/environment';
import { AUDIT_JOB_COLS } from '../../../constants';
import { ButtonComponent } from '../../../Components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { TooltipModule } from 'primeng/tooltip';
import { AuditService } from '../audit.service';
import JSZip from 'jszip';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectService } from '../../project-management/project.services';
import { MessageService } from 'primeng/api';
import { scheduleService } from '../../schedules/schedule.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-job-audit',
  standalone: true,
  templateUrl: './job-audit.component.html',
  styleUrl: './job-audit.component.scss',
  providers: [MessageService],
  imports: [SharedModule, ButtonComponent, DropdownComponent, TooltipModule],
})
export class JobAuditComponent {
  activitiesData: any;
  assessmentCols: TableCols[] = AUDIT_JOB_COLS;
  AssessmentList: any[] = [];
  InstallationList: any[] = [];
  allFields: any = [];
  statusCounts = { pass: 0, reject: 0, empty: 0 };
  rejectDialog: boolean = false;
  formData!: FormGroup;
  AgentList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(5);
  rowData: any;
  onSaveRejectLoad: boolean = false;
  agentNames!: string[];
  jobData: any;
  originalImageUrl: string = '';
  imageDialog: boolean = false;
  isImageLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private http: HttpClient,
    private projectService: ProjectService,
    private messageService: MessageService,
    private schuduleService: scheduleService,
    private sanitizer: DomSanitizer
  ) {
    this.initialForm();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  initialForm(): void {
    this.formData = this.formBuilder.group({
      reason: ['', Validators.required],
      agent: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.activitiesData = history.state.activitiesData;
    this.jobData = history.state.jobData;
    await this.getScheduleData();
    this.getFields();
  }

  private async getScheduleData(): Promise<any[]> {
    try {
      const payload = {
        jobId: this.activitiesData.jobId,
      };
      const res = await this.schuduleService.fetchScheduleData(payload);
      const filteredAgentNames: string[] = Array.from(
        res
          .flatMap((job: any) => job.agents)
          .reduce((map: any, agent: any) => {
            if (!map.has(agent.id)) {
              map.set(agent.id, agent.name);
            }
            return map;
          }, new Map())
          .values()
      );

      this.agentNames = filteredAgentNames;
      return res;
    } catch (error: any) {
      return [];
    }
  }

  updateStatusCounts(data: any): void {
    this.statusCounts = data.reduce(
      (counts: any, item: any) => {
        if (item.status === 'pass') {
          counts.pass++;
        } else if (item.status === 'reject') {
          counts.reject++;
        } else {
          counts.empty++;
        }
        return counts;
      },
      { pass: 0, reject: 0, empty: 0 }
    );
  }

  navigateBack(): void {
    window.history.back();
  }

  async onAuditStatus(status: string, rowData: any): Promise<void> {
    if (status === 'reject') {
      this.AgentList = await this.projectService.fetchAgentList();
      this.rejectDialog = true;
      this.rowData = rowData;
    } else if (status === 'pass') {
      if (rowData.jobFieldValues.at(-1).auditDetails) {
        const payload = [
          {
            id: rowData.jobFieldValues.at(-1).auditDetails.id,
            JobFieldValueId: rowData.jobFieldValues.at(-1).id,
            IsFailed: false,
            RejectReason: '',
            AgentId: null,
          },
        ];
        this.updateFieldStatus(payload);
      } else {
        const payload = [
          {
            JobFieldValueId: rowData.jobFieldValues.at(-1).id,
            IsFailed: false,
            RejectReason: '',
            AgentId: null,
          },
        ];
        this.postFieldStatus(payload);
      }
    }
  }

  private async postFieldStatus(payload: any): Promise<void> {
    try {
      const res = await this.auditService.createFieldStatus(
        payload,
        this.activitiesData.id
      );
      if (res) {
        this.rejectDialog = false;
        this.isLoading = false;
        this.onSaveRejectLoad = false;
        this.getFields();
      }
    } catch (error: any) {
      this.getFields();
      this.onSaveRejectLoad = false;
      this.errorToast(error.message);
    }
  }

  private async updateFieldStatus(payload: any): Promise<void> {
    try {
      const res = await this.auditService.updateFieldStatus(
        payload,
        this.activitiesData.id
      );
      if (res) {
        this.rejectDialog = false;
        this.isLoading = false;
        this.onSaveRejectLoad = false;
        this.getFields();
      }
    } catch (error: any) {
      this.getFields();
      this.onSaveRejectLoad = false;
      this.errorToast(error.message);
    }
  }

  clearStatus(rowData: any): void {
    rowData.status = 'empty';
  }

  async onSaveReject(): Promise<void> {
    this.onSaveRejectLoad = true;
    if (this.rowData.jobFieldValues.at(-1).auditDetails) {
      const { reason, agent } = this.formData.value;
      const payload = [
        {
          id: this.rowData.jobFieldValues.at(-1).auditDetails.id,
          JobFieldValueId: this.rowData.jobFieldValues.at(-1).id,
          IsFailed: true,
          RejectReason: reason,
          AgentId: agent,
        },
      ];
      this.updateFieldStatus(payload);
    } else {
      const { reason, agent } = this.formData.value;
      const payload = [
        {
          JobFieldValueId: this.rowData.jobFieldValues.at(-1).id,
          IsFailed: true,
          RejectReason: reason,
          AgentId: agent,
        },
      ];
      this.postFieldStatus(payload);
    }
  }

  onCancelReject(): void {
    this.rejectDialog = false;
    this.initialForm();
  }

  async getFields(): Promise<any[]> {
    try {
      const response = await this.auditService.fetchFieldData(
        this.activitiesData.id
      );

      const res = response.filter(
        (item: any) => item.field.fieldType !== 'Constant'
      );

      const photoValuesWithId = this.extractPhotoValues(res);

      if (photoValuesWithId) this.isLoading = false;

      const updatedData = this.updateDataWithImages(res, photoValuesWithId);
      this.AssessmentList = updatedData.filter(
        (item: any) => item.stage === 'ASSESSMENT'
      );
      this.InstallationList = updatedData.filter(
        (item: any) => item.stage === 'INSTALLATION'
      );

      this.updateStatusCounts(updatedData);
      this.allFields = updatedData;
      return updatedData;
    } catch (error: any) {
      return [];
    }
  }

  private extractPhotoValues(res: any[]): { id: number; images: any }[] {
    const data = res
      .filter(
        (item) =>
          item.field.fieldType === 'Photo' ||
          item.field.fieldType === 'Signature'
      )
      .map((item) => {
        return {
          id: item.id,
          images: this.handleImages(item.jobFieldValues.at(-1)?.files),
        };
      });
    return data;
  }

  handleImages(res: any): any[] {
    const fields = res;
    const safeUrls: any[] = [];

    for (const filePath in fields) {
      if (fields.hasOwnProperty(filePath)) {
        const base64Data = fields[filePath];
        const safeUrl = this.convertImage(base64Data);
        if (safeUrl) {
          safeUrls.push({ imageBinary: safeUrl, path: filePath });
        }
      }
    }

    return safeUrls;
  }

  convertImage(base64Data: string): any {
    try {
      if (!base64Data || base64Data.trim() === '') {
        return null;
      }
      // Detect the image type based on the base64 string prefix
      let imageType: string;

      if (base64Data.startsWith('/9j/')) {
        imageType = 'image/jpeg';
      } else if (base64Data.startsWith('iVBORw0KGgo')) {
        imageType = 'image/png';
      } else if (base64Data.startsWith('R0lGODlh')) {
        imageType = 'image/gif';
      } else if (base64Data.startsWith('<svg')) {
        imageType = 'image/svg+xml';
      } else {
        return null;
      }

      const base64Image = `data:${imageType};base64,${base64Data}`;
      return this.sanitizer.bypassSecurityTrustUrl(base64Image); // Safe URL for the image
    } catch (error) {
      return null;
    }
  }

  private statusUpdate(data: any) {
    if (data?.auditDetails) {
      if (data.auditDetails.isFailed) return 'reject';
      else return 'pass';
    } else {
      return 'empty';
    }
  }

  private updateDataWithImages(res: any[], imagesData: any[]): DataItem[] {
    return res.map((item) => {
      let metaData: any = '';

      if (item.jobFieldValues[0]?.metadata) {
        const decodedString = item.jobFieldValues[0]?.metadata;

        try {
          const jsonArray = JSON.parse(decodedString);
          const parsedData = jsonArray.map((item: any) => JSON.parse(item));

          metaData = parsedData.map((c: any) => {
            // Extract latitude, longitude, and direction references
            const latitude = c['GPS Latitude'] ?? '';
            const latitudeRef = c['GPS Latitude Ref'] ?? '';
            const longitude = c['GPS Longitude'] ?? '';
            const longitudeRef = c['GPS Longitude Ref'] ?? '';

            // Format latitude and longitude with direction indicators
            const formattedLatitude = latitude
              ? `${latitude} ${latitudeRef}`
              : '';
            const formattedLongitude = longitude
              ? `${longitude} ${longitudeRef}`
              : '';

            return {
              GPSLatitude: formattedLatitude,
              GPSLongitude: formattedLongitude,
              DateTime: c['Date/Time'] ?? '',
            };
          });
        } catch (error) {
          console.error('Error parsing metadata:', error);
        }
      }

      const imageGroup = imagesData.find((group) => group.id === item.id);
      if (imageGroup) {
        return {
          ...item,
          value: imageGroup?.images,
          metaData,
          status: this.statusUpdate(item.jobFieldValues.at(-1)),
        };
      } else {
        return {
          ...item,
          value: item.jobFieldValues.at(-1)?.value || null,
          status: this.statusUpdate(item.jobFieldValues.at(-1)),
        };
      }
    });
  }

  async displayImage(rowData: any) {
    this.isImageLoading = true;
    this.imageDialog = true;
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    const payload = { ImagePaths: [rowData] };
    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        return url;
      });

      const images = await Promise.all(imagePromises);

      this.originalImageUrl = images[0];
      if (images) this.isImageLoading = false;
    } catch (error) {
      this.isImageLoading = false;
    }
  }

  closeDialog(): void {
    this.imageDialog = false;
    this.originalImageUrl = '';
  }
}
